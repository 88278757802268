export const parseUrl = () => {

    const segments = window.location.pathname
        .split('/')
        .slice(-2);

    const type = segments[0] === 'sesje' ? 'session' : 'album';
    const isGallery = type === 'session' && /-galeria$/.test(segments[1]);
    const slug = segments[1];
    
        
    return { type, slug, isGallery };
};
