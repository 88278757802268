import { useState } from 'react';
import axios from 'axios';
import { useGlobalStore } from './';

export const useSendOrder = () => {
	const [ error, setError ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const { dispatch, state } = useGlobalStore();
    const { projectData, selectedPictures } = state;
    
    const sendData = async (isBuyAll) => {
        setIsLoading(true);
        try {
            const url = process.env.REACT_APP_API_URL
                + `/projects/${projectData.type}/${projectData.slug}/confirm`;
            const json = await axios.put(url, {
                pictures_ids: isBuyAll ? projectData.pictures.map(({ id }) => id) : selectedPictures 
            });
            setIsLoading(false);
            dispatch({
                type: 'PROJECT_UPDATED',
                payload: json.data,
            });
            dispatch({type: 'CLEAR_PICTURES_SELECTION'});
        } catch (error) {
            // console.error(error);
            setIsLoading(false);
            setError(error.toString());
        }
    };

    return { error, isLoading, sendData };

};
