import React, { createContext, useReducer } from 'react';
import { loadFromStorage, saveToStorage } from './';

const initialState = {
    language: null,
    projectData: null,
    selectedPictures: [],
};

const getStorageSelectionKey = project => `selection.${project.type}.${project.slug}`;

const reducer = (state, action) => {

    switch (action.type) {

        case 'PROJECT_LOADED':
        case 'PROJECT_UPDATED':
            let selectedPictures = [];
            const projectData = action.payload;
            const storageSelectionKey = getStorageSelectionKey(projectData);
            const storedSelection = loadFromStorage(storageSelectionKey, []);
            if(Array.isArray(storedSelection) && storedSelection.length) {
                selectedPictures = projectData
                    .pictures.filter(picture => storedSelection.includes(picture.id))
                    .map(picture => picture.id);
            }
            return {
                ...state,
                language: projectData.lang,
                projectData,
                selectedPictures,
            };
        
        case 'PICTURE_TOGGLE_SELECT': {
            const id = action.payload;
            const exists = state.selectedPictures.includes(id);
            const selectedPictures = exists
                ? [...state.selectedPictures].filter(item => item !== id)
                : [...state.selectedPictures, id];
            const storageSelectionKey = getStorageSelectionKey(state.projectData);
            saveToStorage(storageSelectionKey, selectedPictures);
            return {
                ...state,
                selectedPictures,
            };
        }

        case 'CLEAR_PICTURES_SELECTION': {
            const storageSelectionKey = getStorageSelectionKey(state.projectData);
            saveToStorage(storageSelectionKey, null);
            return {
                ...state,
                selectedPictures: [],
            }
        }

        default:
            return {...state};
    }
};

const StateContext = createContext(initialState);

const StateProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {children}
        </StateContext.Provider>
    )

};

export { StateContext, StateProvider };
