import axios from 'axios';

const redirectToZipFile = filePath => 
	window.location.href =
		`${process.env.REACT_APP_PUBLIC_URL}/projects/${filePath}`;

export const downloadZipfile = projectData => {

	const { downloaded_at, folder_name, slug, type, zipped_file } = projectData;

	const filePath = `${type}/${folder_name}/${zipped_file}`;

	if(!downloaded_at) {

		axios
			.put(
				`${process.env.REACT_APP_API_URL}/projects/${type}/${slug}/download`
			)
			.then(
				redirectToZipFile(filePath)
			)
			.catch(
				error => console.log(error)
			);

	} else {

		redirectToZipFile(filePath);

	}

};
