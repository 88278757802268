import { useEffect, useState } from 'react';
import { fetchProjectData, parseUrl, useGlobalStore } from './';

export const useFetchProject = () => {

	const [ error, setError ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const { dispatch } = useGlobalStore();

    useEffect(() => {
        const fetchData = (type, slug) => {
            if(type !== '' && slug !== '') {
                setIsLoading(true);
                fetchProjectData({
                    type, slug,
                    onSuccess: json => {
                        setIsLoading(false);
                        dispatch({
                            type: 'PROJECT_LOADED',
                            payload: json.data,
                        });
                        setIsLoaded(true);
                    },
                    onError: error => {
                        console.log(error);
                        setIsLoading(false);
                        if(error.response && error.response.status === 404) {
                            setError('E_404');
                        } else {
                            setError(error.toString());
                        }
                    },
                })
            } else {
                setError('E_404');
            }
        }

        const { type, slug } = parseUrl();
        fetchData(type, slug);
        
    }, [dispatch]);

    return { error, isLoading, isLoaded };

};
