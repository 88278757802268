import React, { lazy, Suspense } from 'react';

import Spinner from './components/Spinner';
import { StateProvider } from './tools';

import './styles/all.scss';

const Project = lazy(() => import('./components/Project'));

export default () => (
	<StateProvider>
		<Suspense fallback={<Spinner standalone />}>
			<Project />
		</Suspense>
	</StateProvider>
);
