import { useState } from 'react';
import axios from 'axios';
import { useGlobalStore } from './';

export const useMakePictureShared = (pictureData) => {
    const [isShared, setIsShared] = useState(pictureData.shared);
	const [ error, setError ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const { state } = useGlobalStore();
    const { projectData } = state;

    const toggleAction = async () => {
        setIsLoading(true);
        try {
            const nextIsShared = !isShared
            const url = process.env.REACT_APP_API_URL
                + `/projects/${projectData.type}/${projectData.slug}/pictures/shared`;
            await axios.put(url, {
                picture_id: pictureData.id,
                flag: nextIsShared
            });
            setIsLoading(false);
            setIsShared(nextIsShared)
        } catch (error) {
            // console.error(error);
            setIsLoading(false);
            setError(error.toString());
        }
    };

    return { isShared, toggleAction, error, isLoading };

};
