export const loadFromStorage = (key, defaultValue=undefined) => {
    try {
        const serialized = localStorage.getItem(key);
        if (serialized === null) {
            return defaultValue;
        }
        return JSON.parse(serialized);
    } catch (error) {
        console.error('localStorage.load', error);
        return defaultValue;
    }
};

export const saveToStorage = (key, value) => {
    try {
        if(value !== null) {
            const serialized = JSON.stringify(value);
            localStorage.setItem(key, serialized);
        } else {
            localStorage.removeItem(key);
        }
        return true;
    } catch (error) {
        console.error('localStorage.save', error);
        return false;
    }
};
