import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import App from './App';
import { isDevMode } from './tools';

// import * as serviceWorker from './serviceWorker';

if(!isDevMode) {
  ReactGA.initialize('UA-108868358-1');
  ReactGA.pageview(window.location.pathname);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
