import React from 'react';
import { useGlobalStore } from './';
import translations from './translations.json';
import Markdown from 'markdown-to-jsx';

const baseLanguage = 'pl';
const allowedLanguages = ['en', 'pl', 'pt'];

const parseParams = (text, params) => {
    if(!params) return text;
    let newText = text;
    Object.keys(params).forEach(key =>
        newText = newText.replace(`%${key}%`, params[key]));
    return (
        <Markdown options={{ forceInline: true }}>
            {newText}
        </Markdown>
    )
};

export const useTranslations = (forceLanguage=null) => {

    const { language } = useGlobalStore().state;
    let curLanguage;

    if(forceLanguage) {
        if(allowedLanguages.includes(forceLanguage)) {
            curLanguage = forceLanguage;
        } else {
            curLanguage = allowedLanguages[0];
        }
    } else {
        curLanguage = language;
    }
    
    return (text, params=null) => {

        if(!(text in translations)) {
            console.error(`Missing translations: ${text}`);
            curLanguage = baseLanguage;
        } else if(curLanguage !== baseLanguage && !(curLanguage in translations[text])) {
            console.error(`Missing translation ${curLanguage}: ${text}`);
            curLanguage = baseLanguage;
        }

        return curLanguage === baseLanguage
            ? parseParams(text, params)
            : parseParams(translations[text][curLanguage], params);
    }

};
