import axios from 'axios';

export const fetchProjectData = async ({ type, slug, onSuccess, onError }) => {
    try {
        const json = await axios
            .get(`${process.env.REACT_APP_API_URL}/projects/${type}/${slug}`);
        onSuccess(json);
    } catch(error) {
        onError(error);
    }
};
